<template>
    <div>
        <form class="card" @submit.prevent.stop="saveRotation()">
            <div class="row">
                <!-- firstname-->
                <div class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> Rotation's Name </label>
                        <input
                            v-model="formData.name"
                            class="form-control"
                            placeholder=""
                            :readonly="disabled"
                            name="name"
                        >
                        <span class="text-danger">{{ errors.first("email") }}</span>
                    </div>
                </div>
                <!-- End of firstname-->
                <!-- firstname-->
                <div class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> Rotation's Email </label>
                        <input
                            v-model="formData.leads_rotations_email"
                            v-validate="'required|email'"
                            data-vv-as="rotation email"
                            type="email"
                            class="form-control"
                            placeholder="me@example.com"
                            :readonly="disabled"
                            name="rotationEmail"
                        >
                        <span class="text-danger">{{ errors.first("rotationEmail") }}</span>
                    </div>
                </div>
                <!-- End of firstname-->

                <div class="col-md-12 mt-4">
                    <h4 class="section-title">
                        Agents:
                    </h4>
                    <agents-table
                        :agents="formData.agents"
                        :disabled="disabled"
                        class="px-2"
                        @saved="addAgent"
                        @deleted="deleteAgent"
                    />
                </div>


                <div v-if="!disabled" class="col-md-6 offset-md-6">
                    <div class="actions d-flex justify-content-end">
                        <button class="btn btn-danger" @click.prevent="$emit('cancel')">
                            Cancel
                        </button>

                        <button class="btn btn-primary ml-2" :disabled="isLoading" @click.prevent="saveRotation()">
                            {{ createButtonText }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div v-if="isEditing" class="mt-5">
            <h3> Rotations Percents</h3>
            <div class="row mt-4">
                <div v-for="item in upcommingReleases" :key="item.title" class="col-md-4">
                    <div class="card padding-20 performance-card">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="main-label">
                                    {{ item.title }}
                                </div>
                                <div class="">
                                    {{ item.subtitle }}
                                </div>
                            </div>

                            <div class="col-md-6 text-right p">
                                <div class="mr-4">
                                    <span class="perf-item__value ext-dot">
                                        {{ item.value }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import listMixins from "@/mixins/listMixins";
import AgentsTable from "./agents-table";

export default {
    components: {
        AgentsTable
    },
    mixins: [
        listMixins
    ],
    props: {
        formData: {
            type: Object,
            default() {
                return {
                    agents: [],
                    rotations: []
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            endpoint: "/leads-rotations",
            upcommingReleases: [
                {
                    title: "Won Leads",
                    subtitle: "",
                    value: "7"
                },
                {
                    title: "Received Leads",
                    subtitle: "",
                    value: "12"
                },
                {
                    title: "Lost Leads",
                    subtitle: "",
                    value: "5"
                }
            ]

        }
    },
    computed: {
        ...mapState({
            company: state => state.Company.data,
            user: state => state.User.data
        }),
        createButtonText() {
            return this.formData.id ? "Save Changes" : "Create"
        }
    },
    mounted() {
        if (this.isEditing) {
            this.getLeadsRotationStats(this.formData.id);
        }
    },
    methods: {
        onSuccess(record) {
            this.$emit("saved", record);
        },

        async saveRotation() {
            const isValid = await this.$validator.validateAll()
            let method = "POST";
            let url = this.endpoint;

            if (!isValid) {
                this.focusFirstError(false)
                return;
            }

            const formData = this.formData
            formData.companies_id = this.company.id;
            formData.users_id = this.user.id

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.onSuccess(data);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }

        },

        addAgent(newAgent) {
            this.formData.agents.unshift(newAgent);
        },

        deleteAgent(id) {
            this.formData.agents = this.formData.agents.filter((agent) => {
                return agent.id != id
            })
        },

        getLeadsRotationStats(id) {
            axios.get('/rotations/'+id+'/stats')
            .then(response => {
                this.upcommingReleases = response.data;
            }).catch(error => {
                
            });
        }
    }
}
</script>

<style lang="scss">
[readonly] {
    color: #333 !important;
}
.perf-card {
    min-height: 52px;
}

.perf-item__value {
    font-weight: bolder;
    font-size: 2rem;
}

.main-label {
    font-weight: bolder;
    font-size: 1.2em;
}

.ext-dot {
    position: relative;

   &::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 10px;
        top: calc(50% - 4px);
        background: green;
        border-radius: 100%;
        right: -20px;
    }
}
</style>
