<template>
    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <td> Name </td>
                    <td style="width:200px">
                        Phone
                    </td>
                    <td style="width:100px">
                        Percent %
                    </td>
                    <td style="width:200px">
                        Actions
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!disabled">
                    <td>
                        <!-- email -->
                        <resource-selector
                            v-model="newAgent.users"
                            v-validate="'required'"
                            :class="{invalid: errors.first('agents')}"
                            :resource="newAgent.users"
                            name="agents"
                            :searchable-by="['firstname','lastname', 'id']"
                            resource-url="/users"
                            :disabled="disabled"
                            resource-id="id"
                            track-by="id"
                            placeholder="Select an agent"
                            :custom-label="userLabel"
                        />
                        <span class="text-danger">{{ errors.first("agents") }}</span>
                        <!-- End of email-->
                    </td>
                    <td>
                        <input
                            v-model="newAgent.phone"
                            class="form-control"
                            placeholder=""
                            :readonly="disabled"
                            name="new-agent-phone"
                        >
                    </td>
                    <td>
                        <div class="input-group" :class="{invalid: errors.first('newAgentPercent')}">
                            <input
                                v-model="newAgent.percent"
                                v-validate="'required|max_value:100|min_value:1'"
                                data-vv-as="percent"
                                class="form-control"

                                placeholder=""
                                :readonly="disabled"
                                name="newAgentPercent"
                            >
                            <div v-if="errors.first('newAgentPercent')" class="input-group-append">
                                <span id="basic-addon2" class="input-group-text" :title="errors.first('newAgentPercent')">
                                    <i class="fa fa-info text-danger" />
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-success" @click.prevent="addAgent">
                            <i class="fa fa-plus" /> Add
                        </button>
                    </td>
                </tr>
                <tr v-for="(agent, index) in agents" :key="`agent-${index}`">
                    <td> {{ userLabel(agent.users) }}</td>
                    <td> {{ agent.phone }}</td>
                    <td> {{ agent.percent }}</td>
                    <td>
                        <button class="btn btn-danger" @click.prevent.stop="deleteAgent(agent.id)">
                            <i class="fa fa-trash" />
                        </button>
                    </td>
                </tr>
                <tr v-if="!agents.length && disabled">
                    <td colspan="4" class="text-center">
                        No agents asigned to this rotation yet
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import ResourceSelector from "@c/resource-selector";
import uuid from "uuid/v4";

export default {
    name: "AgentsTable",
    components: {
        ResourceSelector
    },
    props: {
        agents: {
            type: Array,
            default() {
                return []
            }
        },
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {
            newAgent: {
                users: null
            }
        }
    },
    watch: {
        "newAgent.users"(user) {
            if (user) {
                this.newAgent.users_id = user.id
                this.newAgent.phone = user.cell_phone_number
            } else {
                this.newAgent.users_id = null
                this.newAgent.phone = null
            }
        }
    },
    methods: {
        userLabel(user) {
            return user ? `${user.firstname || ""} ${user.lastname || ""}` : ""
        },

        async addAgent() {
            const isValid = await this.$validator.validateAll();
            if (isValid) {
                this.$emit("saved", { ...this.newAgent, id: uuid() });
                this.newAgent = { users: {} }
            }
        },

        deleteAgent(id) {
            this.$emit("deleted", id);
        }

    }
}
</script>

<style lang="scss">
    .invalid {
        border: 1px solid red;
        &:focus {
            border: 1px solid red;
        }
    }
</style>
